import React, { ComponentType, ReactElement } from "react";
import {
  authenticateUser,
  isRequireAuthentication,
  Authenticating,
  withRouter,
  ReactOidcHistory,
} from "@axa-fr/react-oidc-core";
import { AuthService } from "./../../services";
import { AuthContext } from "./../../services/auth/auth-service";
import { useMemo } from "react";
import { useOidcSecure } from "@axa-fr/react-oidc-context/dist/reactServices/OidcSecure";
import { UserManager } from "oidc-client";
import { oidcLog } from "@axa-fr/react-oidc-context";

type OidcComponentProps = {
  children: React.ReactNode;
  location: Location;
  history: ReactOidcHistory;
  authenticateUserInternal: typeof authenticateUser;
  getUserManagerInternal: () => UserManager;
  isRequireAuthenticationInternal: typeof isRequireAuthentication;
  AuthenticatingInternal: typeof Authenticating;
};

const OidcSecureWithInjectedFunctions = ({
  children,
  location,
  history,
  authenticateUserInternal,
  getUserManagerInternal,
  isRequireAuthenticationInternal,
  AuthenticatingInternal,
}: OidcComponentProps) => {
  const userManager = getUserManagerInternal();
  const WrappedComponent = useMemo(() => () => <>{children}</>, [children]);
  const ReactOidcComponent = useOidcSecure(
    authenticateUserInternal,
    userManager,
    location,
    history,
    oidcLog,
    AuthenticatingInternal,
    isRequireAuthenticationInternal,
    WrappedComponent
  );
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <ReactOidcComponent />;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SecureComponent = withRouter((props: any) => {
  return OidcSecureWithInjectedFunctions(
    Object.assign({}, props, {
      authenticateUserInternal: authenticateUser,
      getUserManagerInternal: () => AuthService.getUserManager(),
      isRequireAuthenticationInternal: isRequireAuthentication,
      AuthenticatingInternal: Authenticating,
    })
  );
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/explicit-module-boundary-types
export const withSecureComponent = (ctx: AuthContext) => <T, _>(
  WrappedComponent: ComponentType<T>
) => (props: T) => (additionalProps: any): ReactElement => (
  <SecureComponent authContext={ctx}>
    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
    {/* @ts-ignore */}
    <WrappedComponent {...props} {...additionalProps} />
  </SecureComponent>
);
