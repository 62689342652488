import { FunctionComponent, useState } from "react";
import { EmbeddedConsole, Login } from "./routes";
import Routes from "./page-routes";

import { Redirect } from "react-router-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
  FullScreenLoader,
  Header,
  NotAuthenticated,
  NotAuthorized,
  SessionLost,
  withSecureComponent,
} from "./components";
import "./index.scss";
import "antd/dist/antd.css";
import { AuthenticationProvider, oidcLog } from "@axa-fr/react-oidc-context";
import { AnalyticsOidcConfiguration } from "./oidc-configuration";
import { ResetPasswordForm } from "./routes/reset-password";
import { LoadDashboard } from "./routes/load-dashboard/load-dashboard";

const AvailabilitySessionLost = () => (
  <SessionLost authContext="analytics"></SessionLost>
);

const handleQueryParams = (): void => {
  // First character is always '?', lets also remove the "p." from the query
  const urlQueryParams = window.location.search
    .substring(1)
    .replaceAll("p.", "");

  const params =
    '{"' +
    decodeURI(urlQueryParams)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"') +
    '"}';

  window.sessionStorage.setItem("parameters", params);
};

const handleDashboardUrl = (): void => {
  const url = window.location.href;
  if (!url.toLowerCase().includes("dashboard")) {
    return;
  }

  const splits = window.location.href.split("/");
  const dashboardGuid = splits[splits.length - 1].split("?")[0];

  // This is where query params are stored in the location object
  if (window.location.search) {
    handleQueryParams();
  }

  window.sessionStorage.setItem("dashboard", dashboardGuid);
};

const App: FunctionComponent = () => {
  // this will check for if the page is within an iframe.
  const [isBrowser] = useState(window.top == window.self);

  // Required to handle auto redirect to the cognito login page
  // This will intercept the request then send it through our flow
  if (
    window.location.href.includes(Routes.Analytics) &&
    !window.location.href.includes("callback")
  ) {
    handleDashboardUrl();

    window.location.href = Routes.Login;
    return <></>;
  }

  return (
    <div className="app">
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <AuthenticationProvider
        configuration={AnalyticsOidcConfiguration}
        loggerLevel={oidcLog.DEBUG}
        callbackComponentOverride={FullScreenLoader}
        authenticating={FullScreenLoader}
        notAuthenticated={NotAuthenticated}
        notAuthorized={NotAuthorized}
        sessionLostComponent={AvailabilitySessionLost}
      >
        {isBrowser && <Header />}

        <Router>
          <Switch>
            <Route
              path={Routes.Analytics}
              component={withSecureComponent("analytics")(EmbeddedConsole)({
                isBrowser,
              })}
            />
            <Route
              path={Routes.Dashboard}
              component={withSecureComponent("analytics")(LoadDashboard)({
                isBrowser,
              })}
            />
            <Route path={Routes.Login} component={Login} />
            <Route path={Routes.ResetPassword} component={ResetPasswordForm} />
            <Route>
              <Redirect to={Routes.Login} />
            </Route>
          </Switch>
        </Router>
      </AuthenticationProvider>
    </div>
  );
};

export default App;
