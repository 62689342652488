import React, { ReactElement } from "react";
import Loader from "../loader";

const FullScreenLoader = (): ReactElement => (
  <div className="d-flex align-items-center justify-content-center w-100 max-height">
    <Loader />
  </div>
);

export default FullScreenLoader;
