import React, { useEffect, useState } from "react";
import { embedSession } from "amazon-quicksight-embedding-sdk";
import Modal from "antd/lib/modal/Modal";
import { useHistory } from "react-router-dom";
import { useAwsIdToken } from "../../hooks";
import { EmbeddedConsoleService } from "../../services";

import { FullScreenLoader } from "../../components";

type StateParams = {
  dashboardId: string | undefined,
  parameters: Record<string, string | string[]>
}

export const EmbeddedConsole = ({ isBrowser, ...props }: { isBrowser: boolean }) => {
  const [loading, setLoading] = useState(true);
  const [token, user] = useAwsIdToken();
  const [errorMessage, setErrorMessage] = useState("");
  const accessToken = user?.access_token;
  const history = useHistory();
  const queryParams = history.location.state as StateParams || {dashboardId: undefined, parameters: {}} as StateParams;
  useEffect(() => {
    const getConsole = async () => {
      const response = await EmbeddedConsoleService.getConsole(
        accessToken || "",
        token || "",
        queryParams.dashboardId || "" // for loading a dashboard when forwarded to this componenent from "/sn/dashboards/:id" route
      );

      if (response instanceof Error) {
        setErrorMessage(response.message);
        return;
      }

      const parameters = window.sessionStorage.getItem("parameters") ?? "{}";

      const options = {
        url: response.EmbedUrl,
        parameters: queryParams.parameters ?? JSON.parse(parameters),
        container: "#embeddedConsole",
        scrolling: "no",
        height: "100%",
        width: "100%",
        footerPaddingEnabled: true,
      };
      embedSession(options);
      window.sessionStorage.removeItem("parameters");
      setLoading(false);
    };
    if (token && !window.location.href.includes("localhost")) {
      getConsole();
    }
  }, [token]);

  if (window.location.href.includes("localhost"))
    return <h3>Cannot view embed console in a localhost instance.</h3>;

  return (
    <>
      {loading && <FullScreenLoader />}
      <div
        id="embeddedConsole"
        className={`w-100 ${isBrowser ? "max-height" : "max-height-no-header"}`}
        style={{
          display: loading ? "none" : "block",
        }}
      ></div>

      <Modal
        visible={!!errorMessage}
        zIndex={1100}
        footer={null}
        onCancel={() => setErrorMessage("")}
      >
        <p className="text-center mt-3" style={{ fontSize: 20 }}>
          {errorMessage}
        </p>
      </Modal>
    </>
  );
};
