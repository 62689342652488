import React, { FC } from 'react';


const NotAuthorized: FC = () => (
  <div className="d-flex flex-column">
    <h1>Authorisation</h1>
    <p>You are not permitted to view this page</p>
  </div>
);

export default NotAuthorized