import { UserManagerSettings } from "oidc-client";

const throwEnvNotFound = (name: string) => {
  throw new Error(`Environment variable ${name} required but not found`);
};
const getEnv = (name: string) => process.env[name] || throwEnvNotFound(name);

const currentLocation = `${window.location.protocol}//${window.location.host}`;

const redirectUri = (callbackPrefix: string) =>
  `${currentLocation}/${callbackPrefix}/auth/callback`;

const userScopes = `openid aws.cognito.signin.user.admin`;

const defaultSettings = (callbackPrefix: string) => ({
  redirect_uri: redirectUri(callbackPrefix),
  response_type: "code",
  authority: getEnv("REACT_APP_OAUTH_URL"),
  silent_redirect_uri: `${redirectUri(callbackPrefix)}/silent`,
  post_logout_redirect_uri: currentLocation, // optional
  metadata: {
    issuer: getEnv("REACT_APP_OAUTH_ISSUER"),
    jwks_uri: `${getEnv("REACT_APP_OAUTH_ISSUER")}/.well-known/jwks.json`,
    authorization_endpoint: `${process.env["REACT_APP_OAUTH_URL"]}/oauth2/authorize`,
    token_endpoint: `${getEnv("REACT_APP_OAUTH_URL")}/oauth2/token`,
    userinfo_endpoint: `${getEnv("REACT_APP_OAUTH_URL")}/oauth2/userInfo`,
    end_session_endpoint: `${getEnv(
      "REACT_APP_OAUTH_URL"
    )}/logout?client_id=${getEnv(
      "REACT_APP_OAUTH_ANALYTICS_CLIENT_ID"
    )}&logout_uri=${currentLocation}`,
  },
});

export const AnalyticsOidcConfiguration: UserManagerSettings = {
  ...defaultSettings("analytics"),
  client_id: getEnv("REACT_APP_OAUTH_ANALYTICS_CLIENT_ID"),
  scope: userScopes,
};
