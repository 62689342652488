import { User, UserManager } from "oidc-client";
import React, { ReactElement, useState } from "react";
import { Menu } from "antd";
import { AnalyticsOidcConfiguration } from "../../oidc-configuration";
import Modal from "antd/lib/modal/Modal";

const HamburgerMenu = (oidcUser: User | null): ReactElement => {
  const [helpModalOpen, setHelpModalOpen] = useState(false);

  const logout = async (
    e: React.MouseEvent<HTMLAnchorElement>
  ): Promise<void> => {
    e.preventDefault();
    const m = new UserManager(AnalyticsOidcConfiguration);

    await m.signoutRedirect();
  };

  const showHelpModal = (): void => {
    setHelpModalOpen(true);
  };

  const hideHelpModal = (): void => {
    setHelpModalOpen(false);
  };

  return (
    <>
      <Menu>
        <Menu.Item key="a">
          <a className="text-left" onClick={showHelpModal}>
            Help
          </a>
        </Menu.Item>
        {oidcUser && (
          <Menu.Item key="b">
            <a className="w-100" href="#" onClick={logout}>
              Logout
            </a>
          </Menu.Item>
        )}
      </Menu>
      <Modal
        zIndex={1100}
        onCancel={hideHelpModal}
        footer={null}
        visible={helpModalOpen}
      >
        <p className="text-center mt-3" style={{ fontSize: 20 }}>
          If you need support accessing or using the <b>Insights Portal</b>{" "}
          please contact Honeysuckle Health on{" "}
          <a href="mailto:support@honeysucklehealth.com.au">
            support@honeysucklehealth.com.au
          </a>
        </p>
      </Modal>
    </>
  );
};

export default HamburgerMenu;
