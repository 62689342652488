import { useReactOidc } from "@axa-fr/react-oidc-context";
import { useEffect, useState } from "react";
import AWS from "aws-sdk";
import { GetOpenIdTokenInput } from "aws-sdk/clients/cognitoidentity";
import { User } from "oidc-client";

const cognitoIdentity = new AWS.CognitoIdentity({
  region: "ap-southeast-2",
});

const tokenCache: Record<string, string> = {};

type Result = [token: string | null, user: User | null];
export const useAwsIdToken: () => Result = () => {
  const [token, setToken] = useState<string>();
  const user = useReactOidc().oidcUser;

  if (!user) {
    return [null, null];
  }

  const idToken = user.id_token;
  useEffect(() => {
    (async () => {
      if (user === null || !process.env.REACT_APP_IDENTITY_POOL_ID) {
        return;
      }

      if (tokenCache[idToken]) {
        setToken(tokenCache[idToken]);
      } else {
        const logins = {
          [`cognito-idp.ap-southeast-2.amazonaws.com/${process.env.REACT_APP_USER_POOL_ID}`]: idToken,
        };

        const getIdParams = {
          IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
          Logins: logins,
        };

        const idData = await cognitoIdentity.getId(getIdParams).promise();

        const openIdRequest = Object.assign({}, idData, {
          Logins: logins,
        }) as GetOpenIdTokenInput;

        const t = await cognitoIdentity.getOpenIdToken(openIdRequest).promise();

        if (t.Token) {
          tokenCache[idToken] = t.Token;
          setToken(t.Token);
        }
      }
    })();
  }, [idToken, user]);

  return [token, user] as Result;
};
