import React, { ReactElement } from "react";
import { LoadingOutlined } from "@ant-design/icons";

const Loader = (): ReactElement => (
  <div className="d-flex align-items-center justify-content-center flex-column loader rounded shadow">
    <h4 className="ant-typography" style={{ paddingBottom: 42 }}>
      Loading Insights Portal
    </h4>
    <LoadingOutlined spin={true} style={{ fontSize: 48 }} />
  </div>
);

export default Loader;
