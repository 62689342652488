import React, { FC } from 'react';


const NotAuthenticated: FC = () => (
  <div className="d-flex flex-column">
    <h1>Authentication</h1>
    <p>You are not authenticated</p>
  </div>
);

export default NotAuthenticated