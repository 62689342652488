import { Redirect, useHistory, useParams } from 'react-router-dom';

/*
This component looks for the dashboard id and query paramters in a url, adds them to the router state
and forwards the page to /analytics for the dashboard and filters to be displayed/applied. See
https://docs.aws.amazon.com/quicksight/latest/user/parameters-in-a-url.html
*/

type BasicObject = {
  [key: string]: string | string[]
};

const getQueryStringParameters = (query: string): BasicObject => (
    query.slice(1) // Remove leading '#'
      .split('&') // Split parameters into an array if there are multiple
      .reduce((parameters: BasicObject, valuePair: string) => {
        const [ key, value ] = valuePair.split('='); // Split into key and values
        //                         ⌄⌄ Check for prototype pullution      ⌄⌄ remove leading 'p.' in key
        const keyName = key.includes('__proto__') ? 'proto' : key.replace('p.', '')
        // Remove URI Encoding, e.g. 20% back to space
        const keyValue = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
        // We can pass multiple filters on the one key, so if the key already exists, turn it into an array of values
        if (Object.prototype.hasOwnProperty.call(parameters, keyName)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          parameters[keyName] = Array.isArray(parameters[keyName])
            ? [ keyValue, ...parameters[keyName] ]
            : [ keyValue, parameters[keyName] ]
        } else {
          parameters[keyName] = keyValue
        }
        return parameters;
      },
    { }
  )
);

export const LoadDashboard = () => {
  const history = useHistory();
  const params: { id : string | undefined } = useParams();
  const { id } = params;
  const hash = history.location.hash;
  const parameters = hash ? getQueryStringParameters(hash) : {}
  return ( 
    <Redirect to={{
      pathname: '/analytics',
      state: { parameters, dashboardId: id }
    }}/>
  )
};