import { withRouter, ReactOidcHistory } from "@axa-fr/react-oidc-core";
import React, { ComponentType, FC, MouseEventHandler } from "react";
import { AuthService } from "../../services";
import { AuthContext } from "../../services/auth/auth-service";

type SessionLostProps = {
  onAuthenticate?: MouseEventHandler;
};

export const SessionLost: FC<SessionLostProps> = ({ onAuthenticate }) => (
  <div className="d-flex flex-column">
    <h1>Session Expired</h1>
    <p>Your session has expired. Please re-authenticate.</p>
    <button
      className="btn btn-info w-25"
      type="button"
      onClick={onAuthenticate}
    >
      Login back in
    </button>
  </div>
);

type SessionLostContainerProps = {
  location: Location;
  history?: ReactOidcHistory;
  authContext: AuthContext;
};

export const SessionLostContainer: FC<SessionLostContainerProps> = () => {
  const onAuthenticate = () => {
    const um = AuthService.getUserManager();

    um.signoutRedirect();
  };
  return <SessionLost onAuthenticate={onAuthenticate} />;
};

export default withRouter(SessionLostContainer as ComponentType) as FC<{
  authContext: AuthContext;
}>;
