import { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Form, Input, Button, Typography, Alert } from 'antd';
import { AuthService, ChangePassword, ChangePasswordWithCode, Login } from '../../services/auth';
import { User, UserManager, UserSettings } from 'oidc-client';
import { AnalyticsOidcConfiguration } from '../../oidc-configuration';
import Routes from '../../page-routes';
import { LoginErrorMessage } from '../../services/auth/auth-service';
import { isBoolean } from 'util';

const { Title } = Typography;

const inputWidth = 300;

interface FormFields {
  email: string,
  password: string
  newPassword: string
}

interface Location extends Login {
  resetFlow: boolean;
}

type FormState = 'input' | 'loading' | 'error'

export const ResetPasswordForm = () => {
  const [form] = Form.useForm()
  const location = useLocation<Location>()
  const history = useHistory();

  const initialFormState: FormState = 'input'
  const [formState, setFormState] = useState<FormState>(initialFormState)
  const [errorMsg, setErrorMsg] = useState<undefined | string>(undefined)
  const resetPasswordFlag: boolean = location.state?.resetFlow;
  const userEmail: string = location.state?.email;

  const onFinish = async (form: FormFields) => {
    setErrorMsg(undefined)
    setFormState('loading')
    const response = resetPasswordFlag
    ? await AuthService.setNewPasswordWithCode({
        email: userEmail,
        code: form.password,
        newPassword: form.newPassword
      } as ChangePasswordWithCode)
    : await AuthService.changePassword({
        email: userEmail,
        oldPassword: form.password,
        newPassword: form.newPassword
      } as ChangePassword);

    if (response instanceof Error) {
      setErrorMsg(response.message);
      setFormState('error')
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    else if (response.access_token) {
      handleSuccessUserLogin(response as UserSettings);

    }
    else {
      setErrorMsg(LoginErrorMessage.UnknownError);
      setFormState('error')
    }
  };

  const handleSuccessUserLogin = async (userSettings: UserSettings) => {
    const m = new UserManager(AnalyticsOidcConfiguration);

    await m.storeUser(new User(userSettings)).then(() => {
      window.location.href = Routes.Analytics;
    });
  };

  return  !userEmail ? <>{history.push('/login')}</> : (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'flex-start'
    }}>
      <div className="reset-password-form" style={{
        background: 'white',
        margin: '100px auto 20px',
        boxShadow: "0px 4px 8px 0px rgb(221 221 221)",
        borderRadius: "4px",
        padding: '42px 42px 20px'
      }}>
        <div className="reset-password-title"
          style={{ marginBottom: '24px' }}>
          <Title level={4} style={{ paddingBottom: '16px' }}>Reset Password</Title>
        </div>
        { resetPasswordFlag && (
          [
            <span key="1">
              Please check your email for the authorisation code
            </span>,
            <br key="2"/>,
            <br key="3"/>
          ]
        )}
        <div className="reset-password-form-inputs">
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            requiredMark={false}
          >
            <Form.Item
              name="email"
              label="Email" style={{ width: inputWidth }}>
              <span className="ant-form-text">{userEmail}</span>
            </Form.Item>
            <Form.Item
              name="password"
              label={resetPasswordFlag ? "Code" : "Old Password"}
              style={{ width: inputWidth }}
              rules={[{ required: true, message: `Please input ${resetPasswordFlag ? 'code' : 'password'}.` }]}>
              <Input.Password placeholder="" />
            </Form.Item>
            <Form.Item
              name="newPassword"
              label="New Password"
              style={{ width: inputWidth }}
              rules={[
                { required: true, message: 'Please input your new password.' },
                { whitespace: true, type: 'string', min: 6, max: 99, message: 'Password must be between 6 and 99 characters long.' },
                { pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=+\-^$*.[\]{}()?"!@#%&/\\,><':;|_~`])\S{6,99}$/, message: 'Password must contain at least one lowercase letter, one uppercase letter, one number and one special character.' },
              ]}>
              <Input.Password placeholder="" />
            </Form.Item>
            <Form.Item
              name="confirmNewPassword"
              label="Confirm New Password"
              style={{ width: inputWidth }}
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password.'
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Passwords entered do not match.'));
                  },
                }),
              ]}>
              <Input.Password placeholder="" />
            </Form.Item>
            <Form.Item>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <a href="/login">
                <Button
                >
                  Cancel
                </Button>
              </a>
                <Button type="primary" htmlType="submit" loading={formState === 'loading'}>Confirm</Button>
              </div>
            </Form.Item>
          </Form>
        </div>
        {
          formState === 'error' &&
          <div className="reset-password-form-error">
            <Alert message={errorMsg} type="error" showIcon />
          </div>
        }
        {errorMsg && <p className="text-danger">{errorMsg}</p>}
      </div>
    </div>
  );
};
