import { User } from "oidc-client";
import { useState, useEffect } from "react";
import { AuthService } from "./../../services";

export const useUsers: () => User | null = () => {
  const [analyticsUser, setAnalyticsUser] = useState<User | null>(null);

  const aToken = analyticsUser?.access_token;

  useEffect(() => {
    (async () => {
      const aUser = await AuthService.getUser();
      setAnalyticsUser(aUser);
    })();
  }, [aToken]);
  return analyticsUser;
};
